<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import CausesData from "./causes-data";

import {
  layoutComputed,
  authComputed,
  causesMethods,
  causesComputed,
} from "@/state/helpers";
/**
 * Causes component
 */
export default {
  page: {
    title: "Causes",
    meta: [{ name: "Causes" }],
  },
  components: {
    Layout,
    PageHeader,
    //CausesData,
  },
  created() {
    this.retrieveCauses();
  },
  data() {
    return {
      title: "Causes",
      items: [
        {
          text: "Nextrack",
        },
        {
          text: "Causes",
          active: true,
        },
      ],

      loaderDashboard: false,

      causesData: [],

    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...causesComputed,
  },
  methods: {
    ...causesMethods,

    retrieveCauses() {
      this.getCausesBy({ limit: 100 });
    },

    showModalSearch() {
      this.$bvModal.show("search-modal");
    },
    hideModalSearch() {
      this.$bvModal.hide("search-modal");
    },

    clear() {
      this.causesFilter = {
        serial: null,
        name: null,
        type: null,
        region: null,
        province: null,
        state: null,
      };
    },

    cancel() {
      return;
    },
    cleanObject(obj) {
      for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  watch: {
    causes(newVal) {
      console.log(newVal);
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
  </Layout>
</template>